
.ui.segment {
   border-radius: .8rem!important;
}

footer{
  background-color: #1b1c1d;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   height: 27px;
   text-align: center;
   color: #FFFFFF;
}

footer p {
   padding: 7px;
   margin: 0px;
   line-height: 100%;
   vertical-align: middle;
}
